<template>
    <div class="login">
        <div class="login-top">
            <img src="../../../assets/images/login-top.png" alt="" />
        </div>
        <div class="login-form">
            <van-form class="input-box">
                <van-cell-group inset>
                    <van-field
                        v-model="phone"
                        type="tel"
                        name="手机号"
                        label="手机号"
                        placeholder="手机号"
                    />
                    <van-field
                        v-model="verifyCode"
                        name="短信验证码"
                        label="短信验证码"
                        placeholder="短信验证码"
                    >
                        <template #button>
                            <van-button
                                size="mini"
                                type="primary"
                                round
                                color="#4AB8AB"
                                :disabled="sendText !== '发送验证码'"
                                @click="sendVerifyCode"
                                >{{ sendText }}
                            </van-button>
                        </template>
                    </van-field>
                </van-cell-group>
                <div style="margin: 16px">
                    <van-button
                        class="login-button"
                        color="#4AB8AB"
                        block
                        type="primary"
                        native-type="submit"
                        @click="login"
                        >绑定
                    </van-button>
                    <!-- <van-radio-group>
              <van-radio use-icon-slot>
                  <van-icon name="passed" size="16" color="#4AB8AB" />
                  我已阅读并同意《服务协议》与《隐私政策》
                  <image slot="icon" src="../../../assets/images/radio.png" />
              </van-radio>
          </van-radio-group> -->

                    <van-checkbox class="checkBox mtb10" v-model="checked" icon-size="16px"
                        >我已阅读并同意<span class="customersInformation" @click="showPopup"
                            >《用户协议与隐私政策》</span
                        ></van-checkbox
                    >
                    <van-popup
                        v-model="show"
                        closeable
                        close-icon="close"
                        position="bottom"
                        :style="{ height: '100%', background: 'linear-gradient(#daf6f0, #fff)' }"
                    >
                        <div class="popupTitle">益耳听力公众号用户服务与隐私政策</div>
                        <div class="popupText">
                            <p class="mb0">
                                “益耳听力服务”微信公众号为无锡矽太益耳听力科技有限公司开发运营。无锡矽太益耳听力科技有限公司拥有所有相关的知识产权。
                            </p>
                            <p class="ftb m0">更新日期：2021年9月30日</p>
                            <p class="ftb m0">生效日期：2021年10月1日</p>
                            <p class="ftb">
                                无锡矽太益耳听力科技有限公司（注册地址为江苏省无锡市新吴区新泰路8号A楼C区2F，以下简称“我们”或“益耳听力”）非常重视用户的隐私和个人信息保护。您在使用我们的产品与／或服务时，我们可能会收集和使用您的相关信息。我们希望通过《益耳听力公众号用户服务与隐私政策》（以下简称“本政策”）向您说明我们在您使用我们的产品与／或服务时如何收集、使用、保存、共享和转移这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。
                            </p>
                            <p class="ftb">
                                请在使用我们的产品或服务前，仔细阅读并了解本政策。一旦您或您授权使用车辆的任何人使用或在我们更新本政策后（我们会及时提醒您更新的情况并重新由您勾选）继续使用我们的产品与／或服务，即意味着您同意本政策（含更新版本）内容，并且同意我们按照本政策收集、使用、保存和共享您的相关信息，本政策即构成您与我们之间具有法律约束力的法律文件，本政策立即对双方产生约束。届时您不应以未阅读本政策内容或者未获得我们对您询问的解答等理由主张本政策无效，或者要求撤销或变更本政策。阅读过程中，如您有任何疑问，可联系我们的客服咨询。
                            </p>
                            <p class="ftb mb0">本政策将帮助您了解以下内容：</p>
                            <p class="m0">一．我们如何收集和使用您的个人信息</p>
                            <p class="m0">二．我们如何使用 Cookie 和同类技术</p>
                            <p class="m0">三．我们如何共享、转移、公开披露您的个人信息</p>
                            <p class="m0">四．我们如何保护和保存您的个人信息</p>
                            <p class="m0">五．您的权利</p>
                            <p class="m0">六．儿童信息的保护</p>
                            <p class="m0">七．您的个人信息如何在全球范围转移</p>
                            <p class="m0">八．本政策如何更新</p>
                            <p class="m0">九．如何联系我们</p>
                            <p class="ftb">
                                益耳听力深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：合法、正当和诚信原则、权责一致原则、目的明确和直接相关原则、选择同意原则、最小必要原则、确保安全原则、主体参与原则、公开透明原则、完整性和准确性原则等。同时，益耳听力承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
                            </p>
                            <p class="ftb">
                                本政策与您所使用的益耳听力提供的各种产品及服务（以下统称“我们的产品或服务”）息息相关，请在使用我们的产品或服务前，仔细阅读并了解本《益耳听力公众号用户服务与隐私政策》。
                            </p>
                            <p class="ftb mb0">一、 我们如何收集和使用您的个人信息</p>
                            <p class="m0">
                                个人信息是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息。本政策中涉及的个人信息包括：<span
                                    class="ftb"
                                    >基本信息（包括姓名、手机号、身份证号码、省份、城市）；个人上网记录（包括页面交互事件、页面停留时长、应用程序使用频率、故障信息、总体使用情况）；个人位置信息（精准定位信息）;个人耳鸣诊疗信息（包括自诉病史、既往就诊史、问诊记录、电子病历、耳鸣治疗仪编号、）；耳鸣治疗仪使用记录（购买日期、验配信息、疗程数、购买治疗服务）；其他信息（包括OpenID
                                    、微信账号信息、邮箱）;</span
                                >
                            </p>
                            <p class="m0">
                                个人敏感信息是指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，本政策中涉及的个人敏感信息包括：<span
                                    class="ftb"
                                    >个人上网记录（包括页面交互事件、页面停留时长）；个人位置信息（精准定位信息）。</span
                                >
                            </p>
                            <p>
                                我们仅会出于本政策所述的以下目的，为完成相关的业务功能而收集和使用您的个人信息：
                            </p>
                            <p class="ftb mb0">（一）您须授权我们收集和使用您个人信息的情形</p>
                            <p class="m0">
                                我们的产品与／或服务包括一些核心功能和服务，<span class="ftb"
                                    >包括线上功能（首页、充值、我的预约、知识库、我的治疗仪、我的充值记录、我的测试报告、我的疗程记录、我的邀请码等）和线下服务（预约、问诊、听觉测试与耳鸣匹配、治疗仪验配、售后服务等）。我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能和服务。如果您不提供相关信息，您将无法享受我们提供的产品与／或服务。</span
                                >这些功能包括：
                            </p>
                            <p class="m0">1 . 添加耳鸣治疗仪</p>
                            <p class="m0">
                                为帮助您绑定耳鸣治疗仪以享受我们提供的验配服务，<span class="ftb"
                                    >我们会请您提供您的真实姓名、性别、出生年月、身份证号码、手机号码、绑定治疗仪的编号和联系地址。</span
                                >我们将使用您提供的信息核对您的耳鸣治疗仪真实性并匹配您的专属服务中心信息。
                            </p>
                            <p class="m0">2．服务与活动预约</p>
                            <p class="m0">
                                为向您提供线下预约服务，<span class="ftb"
                                    >我们会请您提供您的手机号，耳鸣治疗仪编号，身份证号码，以及预约的服务中心，同时我们会向您申请获取您的位置信息，用于选择您附近的特约店向您提供服务，</span
                                >我们将使用上述信息识您的预约需求并与您联系以提供预约服务。
                            </p>
                            <p class="m0">3．治疗信息查询</p>
                            <p class="m0">
                                您可以使用我的功能进行耳鸣治疗仪、充值记录、测试报告、疗程记录和邀请码的查询，如需使用该功能，<span
                                    class="ftb"
                                    >我们会验证您的手机号码，证明是您在亲自操作或由您信任授权的人在操作。</span
                                >
                            </p>
                            <p class="m0">4．充值服务</p>
                            <p class="m0">
                                向您提供充值服务，<span class="ftb"
                                    >我们会请您提供您的手机号码、耳鸣治疗仪编号并核实您的购买日期、使用疗程服务信息，<span
                                        class="bgYello"
                                        >充值会涉及微信支付、银行卡支付等通道信息以完成充值，但我们不保留您的支付通道信息。</span
                                    ></span
                                >
                            </p>
                            <p class="m0">5．安全保障</p>
                            <p class="m0">
                                我们可能会将您的信息用于身份验证、安全防范、反诈骗监测、安全服务等用途。为了确保服务和产品的安全，我们可能记录相关信息，例如：应用程序使用频率、故障信息、总体使用情况。
                            </p>
                            <p class="m0">6．内容浏览</p>
                            <p class="m0">
                                为向您提供更便捷、更符合您需求的信息展示及推送服务，<span
                                    class="ftb"
                                    >我们会便用您的手机号、设备信息和您使用益耳听力服务公众号及其它益耳听力平台时提供的其它信息，</span
                                >提取您的偏好特征，并基于特征标签产出间接人群画像，用于展示、推送信息和广告。我们努力保障您的浏览体验。如果您不想接受我们给您发送的商业广告，您可通过短信或邮件提示回复退订或通过本政策中提供的联系方式进行退订或关闭。
                            </p>
                            <p class="m0">7．客服及争议处理</p>
                            <p class="m0">
                                为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，<span
                                    class="ftb"
                                    >我们可能会保存或与授权的合作公司共享您与我们的通信／通话记录。</span
                                >为了提供服务及改进服务质量的合理需要，我们还可能使用您提供的联系方式主动与您联系以获取您的建议和意见，您可以通过本政策中提供的联系方式进行关闭。
                            </p>
                            <p class="ftb mb0">
                                （二）您可选择是否授权我们收集和使用您的个人信息的情形
                            </p>
                            <p class="m0">
                                为提升您的服务体验，我们的以下附加功能中可能会收集和使用您的个人信息。<span
                                    class="ftb"
                                    >如果您不提供这些个人信息，您依然可以使用上述基本功能。</span
                                >这些附加功能包括：
                            </p>
                            <p class="ftb m0">1．基于相册及摄像头（相机）的附加功能</p>
                            <p class="m0">
                                我们访问您的相册及相机是为了使您可以直接使用本地照片视频及使用摄像头进行拍摄，用于上传或扫描身份证、治疗仪编号等信息绑定。
                            </p>
                            <p class="ftb m0">2．基于定位权限的附加功能</p>
                            <p class="m0">
                                我们申请获取您的定位权限时为了向您提供特约店查询、诊疗协助等服务，我们将使用您的定位信息识别您附近的特约店以向您提供及时且准确的服务。
                            </p>
                            <p class="ftb m0">3．基于通知权限的附加功能</p>
                            <p class="m0">
                                我们申请获取您的通知权限是为了及时向您发出预约提醒、信息变更、服务生效提醒等。
                            </p>
                            <p class="m0">
                                上述附加功能可能需要您在您的设备中向我们开启您的相册、相机（摄像头）、定位、通知权限，以实现这些功能所涉及的信息的收集和使用。您可以决定将这些权限随时开启或关闭（我们会指引您在您的设备系统中完成设置）。<span
                                    class="ftb"
                                    >请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。</span
                                >
                            </p>
                            <p class="ftb mb0">
                                （三）您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意：
                            </p>
                            <p class="m0">1．与国家安全、国防安全有关的；</p>
                            <p class="m0">2．与公共安全、公共卫生、重大公共利益有关的；</p>
                            <p class="m0">3．与犯罪侦查、起诉、审判和判决执行等有关的；</p>
                            <p class="m0">
                                4．出于维护个人信息主题或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
                            </p>
                            <p class="m0">
                                5．所收集的个人信息是个人信息主体自行向社会公众公开的；
                            </p>
                            <p class="m0">
                                6．从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
                            </p>
                            <p class="m0">7．根据您的要求实现产品功能及服务所必需的；</p>
                            <p class="m0">
                                8．用于维护所提供的产品与／或服务的安全稳定运行所必需的，例如发现、处置产品与／或服务的故障，包括车辆召回等；
                            </p>
                            <p class="m0">9．为合法的新闻报道所必需的；</p>
                            <p class="m0">
                                10．学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
                            </p>
                            <p class="m0">
                                11．法律法规规定的其他情形，例如新能源车辆动态数据实时接入国家平台等。
                            </p>
                            <p class="ftb mb0">（四）我们从第三方获得您个人信息的情形</p>
                            <p class="m0">
                                1．我们可能从微信获取您授权共享的个人信息( OpenID
                                、头像、昵称）。我们会将依据与微信的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息；
                            </p>
                            <p class="m0">
                                2．我们会从独立的授权经销商处收集，例如当您通过我们的授权经销商进行听觉测试、购买益耳治疗仪产品、在店办理测试验配服务时进行收集；
                            </p>
                            <p class="m0">
                                3．我们会从我们的商业合作伙伴和／或服务提供商处收集，例如我们可能出于获取潜在客户之目的，从微信营运和其他第三方处获取有关您的个人信息；
                            </p>
                            <p class="ftb mb0">（五）您个人信息使用的规则</p>
                            <p class="m0">
                                1．我们会根据本政策的约定并为实现我们的产品与／或服务功能对所收集的个人信息进行使用。
                            </p>
                            <p class="m0">
                                2．<span class="ftb"
                                    >请您注意，您在使用我们的产品或服务时所提供的所有个人信息，或基于您的单独同意收集的个人敏感信息，除非您删除、撤销同意或通过系统设置拒绝我们收集，否则将在您使用我们的产品或服务期间持续授权我们使用。在您撤销同意或注销账号时，我们将停止使用并在合理的时间内删除您的个人信息。</span
                                >
                            </p>
                            <p class="m0">
                                3．我们会对我们的产品与／或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
                            </p>
                            <p class="m0">
                                4．当我们展示您的个人信息时，我们会采用包括内容替换、去标识化处理方式对您的信息进行脱敏，以保护您的信息安全。
                            </p>
                            <p class="m0">
                                5．当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集额外的信息时，我们会通过您主动做出勾选的形式事先征求您的同意。
                            </p>
                            <p class="ftb mb0">二、我们如何使用 Cookie 和同类技术</p>
                            <p class="m0">
                                为实现您在使用我们的产品／服务时的个性化需求，使您获得更轻松的访问体验，我们会在您的移动设备上发送一个或多个名为
                                Cookie 的小数据文件，指定给您的 Cookie 是唯一的，它只能被将 Cookie
                                发布给您的域中的 Web 服务器读取。我们向您发送 Cookie
                                是为了简化您重复登录的步骤、存储您的访问偏好进而为您提供服务的偏好设置、帮助您优化对广告的选择与互动、帮助判断您的登录状态以及账户或数据安全。我们承诺，不会将Cookie用于本政策所述目的之外的任何用途。我们使用
                                Cookie 和同类技术主要为了实现以下功能或服务：
                            </p>
                            <p class="m0">
                                <span class="ftb">1．授权：</span
                                >您登录益耳听力的相关应用时，我们可通过 Cookie
                                提供正确信息，为您打造个性化的体验。
                            </p>
                            <p class="m0">
                                <span class="ftb">2．安全措施：</span>益耳听力通过 Cookie
                                启用及支持安全功能，并协助检测网上恶意行为和违反本田用户协议的行为。
                            </p>
                            <p class="m0">
                                <span class="ftb">3．偏好、功能和服务：</span> Cookie
                                可记录您的语言设置和交流偏好，也可帮您自动填写益耳听力网站的表格。插件提供的功能、统计和自定义内容，也都由
                                Cookie 提供信息。
                            </p>
                            <p class="m0">
                                <span class="ftb">4．广告：</span>益耳听力可能使用 Cookie
                                向您展示益耳听力线上平台内外的相关广告。我们也会使用 Cookie
                                了解会员是否会再次访问益耳听力线上平台内的广告并在广告主平台上有所操作（例如：下载白皮书或进行购买）。广告主也可能通过
                                Cookie
                                检测益耳听力是否向您展示广告、评估广告展示效果，并向我们提供您与广告的互动信息。我们还与其他线上平台合作，向您在益耳听力线上平台外展示广告。这样，在您使用合作的线上平台或程序后，也能看到我们的广告。
                            </p>
                            <p class="m0">
                                <span class="ftb">5．网站性能、分析和研究：</span>Cookie
                                可帮助益耳听力了解网站和插件在不同地点的性能。您从其他网站、应用、或电脑、移动设备等登录益耳听力线上平台时，我们能通过
                                Cookie 对产品、功能和服务进行了解、改善和研究。
                            </p>
                            <p class="ftb mb0">三、我们如何共享、转移、公开披露您的个人信息</p>
                            <p class="m0 ftb">（一）共享</p>
                            <p class="m0">
                                未经您的单独同意，我们不会与益耳听力以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：
                            </p>
                            <p class="m0">
                                1．我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息；
                            </p>
                            <p class="m0">
                                2．在法律法规允许的范围内，为维护益耳听力、益耳听力的关联方或合作伙伴、您或其他益耳听力用户或社会公众利益、财产或安全免遭损害而有必要提供；
                            </p>
                            <p class="m0">
                                3．<span class="ftb"
                                    >我们可能会征得您的单独授权同意后，将您的个人信息与我们的关联方共享。但我们只会共享必要的个人信息，且受本政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的单独授权同意；</span
                                >
                            </p>
                            <p class="m0">
                                4．<span class="ftb"
                                    >与授权合作伙伴共享：仅为实现本隐私权政策中声明的目的，在征得您的单独授权同意后，我们的某些服务将由我们和授权合作伙伴共同提供。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。</span
                                >我们将审慎评估第三方使用共享信息的目的，对这些合作方的安全保障能力进行综合评估，并要求其遵循合作法律协议。我们会对合作方获取信息的软件工具开发包（
                                SDK )、应用程序接口（ API ）进行严格的安全监测，以保护数据安全。
                            </p>
                            <p class="mb0">目前，我们的授权股东方／合作伙伴包括以下类型：</p>
                            <p class="m0">
                                (1）益耳听力直接或间接的股东：我们可能会将您的个人信息共享给支持我们业务的直接或间接的股东，以使其支持提供益耳听力经营业务或服务。
                            </p>
                            <p class="m0">
                                (2）运营支持服务供应商：我们可能会将您的个人信息共享给支持我们业务功能的第三方。这些支持包括为我们提供系统运营服务、物流配送服务、在线客服、保险服务、救援服务等。
                            </p>
                            <p class="m0">
                                (3）媒体广告服务、数据服务供应商：我们可能会将您的个人信息共享给提供广告、数据服务、用户调研的第三方。我们共享这些信息的目的是为了对您的浏览数据及信息进行分析。
                            </p>
                            <p class="m0">(4）目前，益耳听力服务公众号接入的第三方 SDK 包括：</p>
                            <p class="ftb mb0">微信 SDK</p>
                            <p class="m0">使用目的／功能：获取微信授权、定位、支付等微信基础服务</p>
                            <p class="m0">收集／使用个人信息类型：微信基本信息</p>
                            <p class="m0">
                                官网／隐私政策链接：https://qydevweixin.qq.com/wiki/index.php? title
                                =%E9%A6%96%E9%A1%B5
                            </p>
                            <p class="ftb mb0">益耳听力通信短信 SDK</p>
                            <p class="m0">使用目的／功能：向您发送注册验证码及相关通知短信</p>
                            <p class="m0">收集／使用个人信息类型：手机号</p>
                            <p class="m0">官网／隐私政策链接：http:// ？</p>
                            <p class="ftb mb0">腾讯地图 SDK</p>
                            <p class="m0">使用目的／功能：帮助您查找特约店及救援时定位位置</p>
                            <p class="m0">收集／使用个人信息类型：位置信息</p>
                            <p class="m0">官网／隐私政策链接：https://lbs.qq.com/terms.html</p>
                            <p class="ftb mb0">华为 OCR</p>
                            <p class="m0">
                                使用目的／功能：帮助您自动识别行驶证照片内容，快速注册绑定
                            </p>
                            <p class="m0">收集／使用个人信息类型：行驶证信息</p>
                            <p class="m0">
                                官网／隐私政策链接：https://www.huaweicloud.com/declaration/sa_ prp
                                . html#
                            </p>
                            <p class="ftb mb0">（二）转移</p>
                            <p class="m0">
                                我们不会将您的个人信息转移给任何公司、组织和个人，但以下情况除外：
                            </p>
                            <p class="m0">
                                1．事先获得您单独明确的同意或授权：获得您的单独明确同意后，我们会向其他方转移您的个人信息。如果有法律要求，我们会在转移敏感信息前先告知并获得您的明确单独同意。
                            </p>
                            <p class="m0">
                                2．根据使用的法律法规、法律程序要求、强制性的行政或司法要求、强制性的行政或司法要求所必须的情况进行提供；
                            </p>
                            <p class="m0">
                                3．符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
                            </p>
                            <p class="m0">
                                4．<span class="ftb"
                                    >在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转移，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。</span
                                >
                            </p>
                            <p class="ftb mb0">（三）公开披露</p>
                            <p class="m0">
                                我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：
                            </p>
                            <p class="m0">
                                1．
                                根据您的需求，在您单独明确同意的披露方式下披露您所指定的个人信息；
                            </p>
                            <p class="m0">
                                根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。
                            </p>
                            <p class="ftb mb0">四、我们如何保护和保存您的个人信息</p>
                            <p class="ftb mb0">（一） 我们保护您个人信息的技术与措施</p>
                            <p class="m0">
                                我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：
                            </p>
                            <p class="m0">
                                1．我们已使用符合业界标准的安全防护措施保护您的个人信息，防止您的个人信息遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。益耳听力会采取一切合理可行的措施，保护您的个人信息。
                            </p>
                            <p class="m0">
                                2．我们的核心业务系统通过了中华人民共和国网络安全等级保护认证、27001认证。
                            </p>
                            <p class="m0">
                                3．我们采取加密技术及相关合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。
                            </p>
                            <p class="m0">
                                4．我们会建立专门的安全部门、安全管理制度、数据安全流程保障您的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人信息，并适时对数据和技术进行安全审计。
                            </p>
                            <p class="m0">
                                5．尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
                            </p>
                            <p class="m0">
                                6．为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了个人信息泄露的应急处置措施，针对泄露事件建立了事件上报和对应的处置流程。建立了专门的应急响应团队。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。<span
                                    class="ftb"
                                    >如果您发现自己的个人信息泄露，请您立即通过本政策【九、如何联系我们】中约定的联系方式联络我们，以便我们采取相应的措施。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span
                                >
                            </p>
                            <p class="ftb mb0">（二） 您个人信息的保存</p>
                            <p class="ftb mb0">1．存储地点</p>
                            <p class="m0">
                                我们依照法律法规的规定，将收集和产生的您的个人信息存储于中华人民共和国境内。若需境外转移，我们将会遵循相关国家规定征得您的单独同意。
                            </p>
                            <p class="ftb mbo">2．存储期限</p>
                            <p class="m0">
                                我们仅在为提供益耳听力服务公众号服务之目的所必需的期间内保留您的个人信息（如法律法规及监管规定要求最短保存期间的，则不少于该最短保存期间；如法律法规及监管规定要求最长保存期间的，不超过该最长保存期间。)。在您未删除或未注销账号期间，我们会保留相关信息。超出必要期限后，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外，我们判断上述期限的标准包括：
                            </p>
                            <p class="m0">●法律法规或监管有关数据保留期的特别要求；</p>
                            <p class="m0">
                                ●完成与您相关的交易目的、维护相应交易及业务记录、应对您可能的查询或投诉；
                            </p>
                            <p class="m0">●保证我们为您提供服务的安全和质量；</p>
                            <p class="m0">●您是否同意更长的留存期间；</p>
                            <p class="m0">●是否存在保留期限的其他特别约定。</p>
                            <p class="ftb">
                                如果我们终止服务或运营、我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。
                            </p>
                            <p class="ftb fb0">五、您的权利</p>
                            <p class="m0">
                                我们非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：
                            </p>
                            <p class="ftb m0">1． 访问和更正、补充您的个人信息</p>
                            <p class="m0">
                                除法律法规规定外，您有权随时访问和更正、补充您的个人信息并获取个人基本资料，您可以通过【我的】进行个人信息的访问和更正、补充。
                            </p>
                            <p class="ftb m0">2. 获取个人信息副本</p>
                            <p class="m0">
                                您有权获取您的个人信息副本，您可以通过本政策【九、如何联系我们】中约定的联系方式联系我们。在技术可行的前提下，例如数据接口匹配，我们还可按您的要求，直接将您的个信息副本传输给您指定的第三方。
                            </p>
                            <p class="ftb m0">3. 删除您的个人信息</p>
                            <p class="m0">
                                在以下情形中，您可以通过本政策【九、如何联系我们】中约定的联系方式向我们提出删除个人信息的请求：
                            </p>
                            <p class="m0">(1）如果我们处理个人信息的行为违反法律法规；</p>
                            <p class="m0">
                                (2）如果我们收集、使用您的个人信息，却未征得您的同意，或者我们在您撤回同意后仍然收集、使用您的个人信息；
                            </p>
                            <p class="m0">(3）如果我们处理个人信息的行为违反了与您的约定；</p>
                            <p class="m0">(4）如果您注销了益耳听力服务账户；</p>
                            <p class="m0">(5）如果我们终止服务及运营；</p>
                            <p class="m0">
                                (6）如果处理目的已实现、无法实现或者为实现处理目的不再必要；
                            </p>
                            <p class="m0">(7）如果第四条第（二）款规定的存储期限已届满。</p>
                            <p class="m0">
                                若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。<span
                                    class="ftb"
                                    >当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。对于个人敏感信息，我们保证收到您的删除请求之日起十个エ作日内进行删除。</span
                                >
                            </p>
                            <p class="ftb m0">4.改变您授权同意的范围或撤回您的授权</p>
                            <p class="m0">
                                您可以通过删除信息、关闭设备功能等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。
                            </p>
                            <p class="m0">
                                <span class="ftb"
                                    >请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</span
                                >
                            </p>
                            <p class="ftb m0">5．转移您的个人信息</p>
                            <p class="m0">
                                <span class="ftb"
                                    >在符合国家网信部门规定条件的前提下，您可以通过致电联系我们客服的方式向我们请求将您的个人信息转移至您指定的个人信息处理者。</span
                                >
                            </p>
                            <p class="ftb m0">6．注销账户</p>
                            <p class="m0">
                                <span class="ftb"
                                    >您可以通过本政策【九、如何联系我们】中约定的联系方式联系我们进行账户注销，您的注销申请将在15个工作日内处理完成。</span
                                >为了保护您的合法权益，在您注销帐号前，我们将验证您的个人身份、安全状态、设备信息等。注销账户后，我们将停止为您提供相关产品与／或服务，并依据您的要求删除有关您的相关信息或进行匿名化处理，但法律法规另有规定的除外。
                            </p>
                            <p class="ftb m0">7．投诉举报</p>
                            <p class="m0">
                                如果您认为您的个人信息权利可能收到侵害，或者发现侵害个人信息权利的线索，您可以通过本政策【九、如何联系我们】中约定的联系方式与我们联系。我们核查后会在15个工作日内反馈您的投诉与举报。
                            </p>
                            <p class="ftb m0">8．响应请求</p>
                            <p class="m0">
                                为保障您的个人信息安全，我们会对您的身份进行核验，然后再处理您的请求。<span
                                    class="ftb"
                                    >我们将在15天内对您提出的请求做出响应。</span
                                >请您理解，对于那些无端重复、需要过多技术手段（例如需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如询问他人信息）的请求，我们可能会予以拒绝。
                            </p>
                            <p class="ftb mb0">六、儿童信息的保护</p>
                            <p class="m0">
                                1．我们非常重视对儿童个人信息的保护，我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不应创建自己的个人信息主体账户。
                            </p>
                            <p class="m0">
                                2．对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。
                            </p>
                            <p class="m0">
                                3．尽管当地法律和习俗对儿童的定义不同，但我们将不满14周岁的任何人均视为儿童。
                            </p>
                            <p class="m0">
                                4．如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。
                            </p>
                            <p class="ftb mb0">七、您的个人信息如何在全球范围转移</p>
                            <p class="m0">
                                原则上，我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内。
                            </p>
                            <p class="m0">
                                由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的单独授权同意，并履行相关法律法规规定的手续后，您的个人信息可能会被转移到您使用产品或服务所在国家／地区的境外管辖区，或者受到来自这些管辖区的访问。
                            </p>
                            <p class="m0">
                                此类管辖区可能设有不同的数据保护法。在此类情形下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。
                            </p>
                            <p class="ftb mb0">八、本政策如何更新</p>
                            <p class="m0">
                                1．为给您提供更好的服务以及随着益耳听力业务的发展，本政策也会随之更新。未经您明确同意，我们不会削减您依据本政策所应享有的权利。我们会通过在益耳听力服务公众号公布更新版本政策并在生效前通过益耳听力服务公众号内公告或以其他适当方式提醒您相关内容的更新。
                            </p>
                            <p class="m0">
                                2．对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容以获取您的授权同意，本政策所指的重大变更包括但不限于：
                            </p>
                            <p class="m0">
                                (1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
                            </p>
                            <p class="m0">
                                (2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
                            </p>
                            <p class="m0">(3）个人信息共享、转移或公开披露的主要对象发生变化；</p>
                            <p class="m0">
                                (4）您参与个人信息处理方面的权利及其行使方式发生重大变化；
                            </p>
                            <p class="m0">(5）我们负责的联络方式及投诉渠道发生变化时；</p>
                            <p class="m0">(6）个人信息安全影响评估报告表明存在高风险时。</p>
                            <p class="m0">我们还会将本政策的旧版本存档，供您查阅。</p>
                            <p class="ftb mb0">九、如何联系我们</p>
                            <p class="m0">
                                如您对本政策或您个人信息的相关事宜有任何疑问、意见或建议，您可以通过以下方式联系我们，我们将在15个工作日内回复：
                            </p>
                            <p class="m0"><span class="ftb">400热线 : 4 00-828-7112</span></p>
                            <p class="m0">
                                若需要解决外部争议和纠纷时，任何一方应向无锡市新吴区人民法院提起诉讼。
                            </p>
                            <p class="ftb mb0 textC">补充政策一</p>
                            <p class="ftb m0 textC">个人信息的共享规则</p>
                            <p>
                                本规则为【益耳听力公众号用户服务与隐私政策】的补充，本规则未尽事宜，以【益耳听力公众号用户服务与隐私政策】为准。请在使用我们的产品或服务前，仔细阅读并了解本规则，在确认充分理解并同意后再开始使用。阅读过程中，如您有任何疑问，可联系我们。如您不同意本规则中的任何条款，我们将不会将您的个人信息共享给我们的关联公司和授权合作伙伴。
                            </p>
                            <p class="ftb mb0">一、共享信息的目的、方式及种类</p>
                            <p class="m0">
                                我们将下述个人信息与我们的关联公司和授权合作伙伴共享，或委托上述主体处理您的个人信息。
                            </p>
                            <p class="ftb mb0">个人信息</p>
                            <p class="m0">VIN</p>
                            <p class="m0">设备型号</p>
                            <p class="m0">性别</p>
                            <p class="m0">城市</p>
                            <p class="m0">媒体信息</p>
                            <p class="m0">微信平台使用情况</p>
                            <p class="ftb mb0">共享目的</p>
                            <p class="m0">用户身份识别</p>
                            <p class="m0">服务提醒</p>
                            <p class="m0">根据位置信息的相关服务</p>
                            <p class="m0">为用户提供精准主动服务</p>
                            <p class="ftb mb0">共享方式</p>
                            <p class="m0">数据接口传输</p>
                            <p class="m0">后台报表下载</p>
                            <p class="m0">消息推送（模板消息、短信等）</p>
                            <p class="m0">
                                （请注意，如您不同意我们使用您的个人信息，则可能对您使用【益耳听力服务】微信公众号功能／服务产生障碍，或无法达到该等功能／服务拟达到的效果。)
                            </p>
                            <p class="ftb mb0">二、共享信息的接收方</p>
                            <p class="m0">所有我们可能共享您个人信息的关联公司及授权</p>
                            <p class="m0">合作伙伴的联系方式如下表：</p>
                            <p class="ftb mb0">名称</p>
                            <p class="m0">益耳听力特约销售服务店</p>
                            <p class="ftb mb0">电子邮箱</p>
                            <p class="m0">详见益耳听力官网、售后微信服务号、三包手册</p>
                            <p class="ftb mb0">联系电话</p>
                            <p class="m0">400-828-7112</p>
                            <p class="ftb mb0">通信地址</p>
                            <p class="m0">详见益耳听力官网、售后微信服务号、三包手册</p>
                            <p class="ftb mb0">三、无需取得您的同意的情形</p>
                            <p class="m0">
                                未经您的同意，我们不会与我们以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：
                            </p>
                            <p class="m0">(1）与我们履行法律法规规定的义务相关的；</p>
                            <p class="m0">(2)与国家安全、国防安全直接相关的；</p>
                            <p class="m0">
                                (3）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需的；
                            </p>
                            <p class="m0">(4）与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
                            <p class="m0">
                                (5）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人授权同意的；
                            </p>
                            <p class="m0">(6）您自行向社会公众公开的个人信息；</p>
                            <p class="m0">
                                (7）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
                            </p>
                            <p class="m0">
                                (8）用于维护所提供的产品与／或服务的安全稳定运行所必需的，例如发现、处置产品与／或服务的故障等；
                            </p>
                            <p class="m0">(9）法律、行政法规规定的其他情形。</p>
                            <p class="ftb mb0">四、我们如何保护您的个人信息</p>
                            <p class="m0">
                                我们只会共享实现本规则所规定的共享目的所必要的个人信息，并会严格限制接收方在上述共享目的、共享方式和个人信息的种类等范围内处理个人信息。接收方变更原先的共享目的、共享方式的，会重新取得您的同意。
                            </p>
                            <p class="m0">
                                关于我们如何保护您个人信息的技术与措施、保存期间、您的相关权利等更多处理规则相关内容，请访问我们的【益耳听力公众号用户服务与隐私政策】以了解更详细的情况。
                            </p>
                            <p class="m0">
                                如您对上述说明存在疑问，可与我们的个人信息保护机构取得联系，联系电话：400-828-7112。
                            </p>
                            <p class="ftb mb0 textC">补充政策二</p>
                            <p class="ftb m0 textC">敏感个人信息处理规则</p>
                            <p class="mb0">
                                本规则为【益耳听力公众号用户服务与隐私政策】的补充，本规则未尽事宜，以【益耳听力公众号用户服务与隐私政策】为准。请在使用我们的产品或服务前，仔细阅读并了解本规则，在确认充分理解并同意后再开始使用。阅读过程中，如您有任何疑问，可联系我们。<span
                                    class="ftb"
                                    >如您不同意本规则中的任何条款，请停止提交您个人敏感信息的行为。</span
                                >
                            </p>
                            <p class="ftb mb0">一、敏感个人信息的处理目的、处理方式及种类</p>
                            <p class="m0">
                                敏感个人信息是一旦泄露或者非法使用，容易导致您的人格尊严受到侵害或者人身、财产安全受到危害的个人信息。
                            </p>
                            <p class="m0">我们会通过下列的方式处理下述的敏感个人信息。</p>
                            <p class="ftb mb0">敏感个人信息</p>
                            <p class="m0">手机号码</p>
                            <p class="m0">身份证号码</p>
                            <p class="m0">治疗信息</p>
                            <p class="m0">交易记录</p>
                            <p class="ftb mb0">处理目的</p>
                            <p class="m0">治疗仪注册认证／解绑</p>
                            <p class="m0">工单记录（消费信息）</p>
                            <p class="m0">线上销售等线上支付功能使用</p>
                            <p class="ftb mb0">处理方式</p>
                            <p class="m0">用户身份识别</p>
                            <p class="m0">治疗信息、交易记录展示（对客户本人）</p>
                            <p class="m0">线上支付订单处理</p>
                            <p class="ftb m0">
                                （请注意，如您不同意我们处理【敏感个人信息】信息，则可能对您使用【益耳听力服务】微信公众号功能／服务产生障碍，或无法达到该等功能／服务拟达到的效果。)
                            </p>
                            <p class="ftb mb0">二、无需取得您的同意的情形</p>
                            <p class="m0">
                                未经您的同意，我们不会处理您的敏感个人信息，但以下情况除外：
                            </p>
                            <p class="m0">① 与我们履行法律法规规定的义务相关的；</p>
                            <p class="m0">② 与国家安全、国防安全直接相关的；</p>
                            <p class="m0">
                                ③
                                为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需的；
                            </p>
                            <p class="m0">④ 与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
                            <p class="m0">
                                ⑤
                                出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人授权同意的；
                            </p>
                            <p class="m0">⑥ 您自行向社会公众公开的个人信息；</p>
                            <p class="m0">
                                ⑦
                                从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
                            </p>
                            <p class="m0">
                                ⑧
                                用于维护所提供的产品与／或服务的安全稳定运行所必需的，例如发现、处置产品与／或服务的故障，包括车辆召回等；
                            </p>
                            <p class="m0">⑨ 法律、行政法规规定的其他情形。</p>
                            <p class="m0">
                                ⑩
                                从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
                            </p>
                            <p class="m0">
                                ⑪
                                用于维护所提供的产品与／或服务的安全稳定运行所必需的，例如发现、处置产品与／或服务的故障，包括车辆召回等；
                            </p>
                            <p class="m0">⑫ 法律、行政法规规定的其他情形。</p>
                            <p class="ftb mb0">三、我们如何保护您的个人信息</p>
                            <p class="m0">
                                我们只会处理实现本规则所规定的处理目的所必要的敏感个人信息。关于我们如何保护您敏感个人信息的技术与措施、保存期间、您的相关权利等更多处理规则相关内容，请访问我们的【益耳听力公众号用户服务与隐私政策】以了解更详细的情况。
                            </p>
                            <p class="m0">
                                如您对上述说明存在疑问，可与我们的个人信息保护机构取得联系。
                            </p>
                        </div>
                    </van-popup>
                </div>
            </van-form>
        </div>
    </div>
</template>
<style scoped>
.login {
    background: #fff;
    height: 100%;
}

.login-top img {
    width: 100%;
}

.login-form {
    background: #fff;
    position: relative;
    top: -20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    padding: 80px 15px;
}

.van-cell {
    padding: 10px 25px;
}

.login-button {
    width: 60%;
    margin: 0 auto;
}

.van-radio-group {
    margin-top: 10px;
}

.van-button--mini {
    padding: 0 10px;
    line-height: 1;
}

.checkBox {
    text-align: center;
    justify-content: center;
}

.popupTitle {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    padding: 40px 0 5px 0;
}

.popupText {
    padding: 0 30px;
    font-size: 12px;
    color: #666;
    line-height: 24px;
    height: calc(100% - 64px);
    overflow-y: auto;
}

.popupText p {
    text-indent: 15px;
}

.customersInformation {
    color: #4ab8ab;
}
</style>
<script>
import { toast, toFirstPage, verifyPhone} from '@/util';
import { sendVerifyCode } from '@/services/sms';
import { bindPhone } from '@/services/passport';
import { getUser } from '@/services/user';

export default {
    data() {
        return {
            sendText: '发送验证码',
            phone: '',
            verifyCode: '',
            active: 0,
            show: false,
            checked: true,
        };
    },
    async created() {
    },
    methods: {
        async sendVerifyCode() {
            if (!this.phone) {
                return '请输入手机号';
            }
            if (!verifyPhone(this.phone)) {
                return '手机号格式错误';
            }
            const { code, message } = await sendVerifyCode({
                phone: this.phone,
                scene: 'Login',
            });
            if (!code) {
                return toast(message);
            }
            let second = 60;
            this.sendText = `${second}s后重新发送`;
            const timer = setInterval(() => {
                second--;
                if (second) {
                    this.sendText = `${second}s后重新发送`;
                } else {
                    this.sendText = '发送验证码';
                    clearInterval(timer);
                }
            }, 1000);
        },

        async login() {
            const err = this.verify();
            if (err) {
                return toast(err);
            }
            let wxInfo = JSON.parse(localStorage.getItem('wxInfo'));console.log(wxInfo);
            const { code, data,  message } = await bindPhone({
                phone: this.phone,
                verifyCode: this.verifyCode,
                invitorId: localStorage.getItem('invitorId') || 0,
                openId: wxInfo.openId,
                unionId: wxInfo.unionId,
                nickName: wxInfo.nickName,
                headImgURL: wxInfo.headImgURL,
                sex: wxInfo.sex
            });
            if (!code) {
                return toast(message);
            }
            const { userId, token } = data;
            localStorage.setItem('userId', userId);
            localStorage.setItem('token', token);
            await this.getUser();
        },

        async getUser() {
            const { code, message, data } = await getUser();
            if (!code) {
                toast(message);
            }
            toFirstPage(this.$router, data);
        },

        verify() {
            if (!this.phone) {
                return '请输入手机号';
            }
            if (!verifyPhone(this.phone)) {
                return '手机号格式错误';
            }
            if (this.verifyCode.length !== 6) {
                return '验证码错误';
            }
            if (!this.checked) {
                return '请先勾选同意《用户协议与隐私政策》';
            }
        },
        showPopup() {
            this.show = true;
        },
    },
};
</script>
