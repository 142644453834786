import { Method, request, Url } from '@/services/base';

async function login({ phone, verifyCode, wxCode, invitorId }) {
    return await request(Url.passport.login, Method.post, { phone, verifyCode, wxCode, invitorId });
}

async function loginByWxCode({ wxCode, invitorId }) {
    return await request(Url.passport.loginByWxCode, Method.post, { wxCode, invitorId });
}

async function bindPhone({ phone, verifyCode, invitorId, openId, unionId, nickName, headImgURL, sex }) {
    return await request(Url.passport.bindPhone, Method.post, { phone, verifyCode, invitorId, openId, unionId, nickName, headImgURL, sex });
}

export { login, loginByWxCode, bindPhone };
